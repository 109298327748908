import { StaticQuery, graphql } from 'gatsby';

import { LanguageContext } from 'common/src/contexts/LanguageContext'
import React from 'react';
import { TextPage } from '../../pageTemplates/text';
import { withPreview } from 'gatsby-source-prismic-graphql';

const seoDataQuery = graphql`
  query {
    prismic {
      allLanding_pages(lang: "en-gb") {
        edges {
          node {
            document_seo_title
            document_seo_description
            hero_image
            ...NavbarContent
          }
        }
      }
      allPages(tags: "refund", lang: "en-gb") {
        edges {
          node {
            content
            title
          }
        }
      }
    }
  }
`;

export default () => {
  return (
    <LanguageContext.Provider value={{ lang: 'en' }}>
      <StaticQuery
        query={`${seoDataQuery}`}
        render={withPreview(TextPage, seoDataQuery)}
      />
    </LanguageContext.Provider>
  );
};
